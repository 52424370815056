<script setup>
  import { countdownTime, isSameDay } from '@/views/pay/compontent/calculateTime.js'
  import couponCard from './compontent/coupon-card'
  import couponCodeDialog from './compontent/coupon-code-dialog'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, nextTick, watch } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const couponCodeDialogRef = ref()

  const props = defineProps({
    menuIndex: {
      type: Number
    },
    isBox: {
      type: Boolean,
      default: false
    }
  })

  const data = reactive({
    menuType: 0,
    twoCategoriesHistoryType: 'Expired',
    couponMenuList: [
      { type: 'collect', name: 'Collect Coupons', zhName: '可领取优惠卷列表', isLoaded: false, list: [] },
      { type: 'Available', name: 'Available Coupons', zhName: '可使用优惠卷列表', isLoaded: false, list: [] },
      { type: 'History', name: 'Coupons History', zhName: '优惠卷历史列表', isLoaded: false, list: [] }
    ]
  })

  let countdownTimers = []
  const twoCategoriesHistoryList = ['Expired', 'Used', 'Shared']

  // 获取优惠卷列表
  const getCouponList = () => {
    switch (data.menuType) {
      case 1:
        if (data.couponMenuList[1].isLoaded) return
        // 查询券可使用列表
        api.coupon.getCouponByUseridReq(userStore.userInfo.userId).then((res) => {
          console.log('可使用优惠卷列表', res)
          data.couponMenuList[1].list = res
          startCountdown()
          setTimeout(() => {
            data.couponMenuList[1].isLoaded = true
          })
        })
        break
      case 2:
        if (data.couponMenuList[2].isLoaded) return
        // 查询券已使用列表
        getCouponUsed()
        break
      default:
        if (data.couponMenuList[0].isLoaded) return
        getCouponCollectList()
        break
    }
  }

  // 整体倒计时
  const startCountdown = () => {
    countdownTimers = data.couponMenuList[1].list.map((item, index) => {
      item.coupon.countdown = ''
      if (isSameDay(item.coupon.endAt)) {
        countdownTime(
          item.coupon.endAt,
          (formattedTime) => {
            // 在回调函数中处理格式化后的倒计时字符串
            // 这里可以更新页面上显示倒计时的元素
            item.coupon.countdown = formattedTime
          },
          'HH:MI:SS'
        )
      }
    })
  }

  // 查询优惠卷Collect列表
  const getCouponCollectList = () => {
    let params = {
      'executed.equals': 'true',
      'logicalDeleted.equals': 'false',
      'unissuedCount.greaterThan': 0,
      size: 100,
      page: 0,
      sort: 'createdDate,desc'
    }
    api.coupon
      .singleclick(userStore.userInfo.userId, params)
      .then((res) => {
        // isuse 已领取和未领取
        const couponTypeList = ['PRODUCT_DISCOUNT', 'REDUCED_FREIGHT']
        const allCouponList = res.filter((item) => {
          return couponTypeList.includes(item.couponType)
        })
        data.couponMenuList[0].list = res
        console.log('收集优惠卷列表', res)
      })
      .finally(() => {
        // console.log(data.couponMenuList, 'couponMenuList')
        data.couponMenuList[0].isLoaded = true
      })
  }

  // 历史优惠卷列表
  const getCouponUsed = () => {
    data.couponMenuList[2].isLoaded = false
    data.couponMenuList[2].list = []
    let params = {}
    switch (data.twoCategoriesHistoryType) {
      case 'Used':
        params = {
          'executed.equals': false,
          'isuse.equals': true,
          'logicalDeleted.equal': false,
          'receiveUserId.specified': false
        }
        break
      case 'Shared':
        params = {
          'executed.equals': false,
          'isuse.equals': false,
          'logicalDeleted.equal': false,
          'receiveUserId.specified': true
        }
        break
      default:
        params = {
          'executed.equals': false,
          'receiveUserId.specified': false,
          'isuse.equals': false,
        }
        break
    }
    api.coupon.getCouponUsed(userStore.userInfo.userId, params).then((res) => {
      console.log('优惠卷历史列表', res)
      data.couponMenuList[2].list = res
      data.couponMenuList[2].isLoaded = true
    })
  }

  // 切换优惠卷类型
  const changeType = (index) => {
    data.menuType = index
    userStore.setUserShowPage({ selectType: data.couponMenuList[index].name })
    getCouponList()
  }

  // 弹出兑换码弹窗
  const exchangeCouponDialog = async () => {
    await nextTick()
    couponCodeDialogRef.value.dialogShow()
  }

  // 兑换码成功翻新状态
  const getCouponCodeSuc = async () => {
    console.log('翻新状态')
    data.couponMenuList.forEach((item) => {
      item.isLoaded = false
    })
    // console.log(data.couponMenuList, 'data.couponMenuList')
    getCouponList()
  }

  // 切换历史优惠卷列表类型
  const changeHistoryType = (item) => {
    data.twoCategoriesHistoryType = item
    getCouponUsed()
  }

  watch(
    () => props.menuIndex,
    (newVal, oldVal) => {
      const index = newVal ? newVal : 0
      changeType(index)
    },
    { immediate: true }
  )

  // onMounted(() => {
  //   changeType(0)
  // })
</script>

<template>
  <div class="coupon-box" :style="{ width: props.isBox ? '100%' : 'var(--minWidth)' }">
    <div class="coupon-header-box">
      <div class="rowBC menu-box">
        <div class="rowSC">
          <div v-for="(item, index) in data.couponMenuList" :key="index" class="menu-item" :class="index === data.menuType ? 'active' : ''" @click="changeType(index)">
            {{ item.name }}
          </div>
        </div>
        <div class="redeem-coupon" @click="exchangeCouponDialog">Redeem Coupon</div>
      </div>
      <div class="rowSC mt10 menu-box2" v-show="data.menuType === 2">
        <div
          v-for="(item, index) in twoCategoriesHistoryList"
          :key="index"
          class="menu-item mr50"
          :class="item === data.twoCategoriesHistoryType ? 'active' : ''"
          @click="changeHistoryType(item)"
        >
          {{ item }}
        </div>
      </div>
    </div>

    <!-- 优惠卷列表 -->
    <div class="card-box rowSS" v-loading="!data.couponMenuList[data.menuType].isLoaded">
      <template v-if="data.couponMenuList[data.menuType].list.length > 0">
        <div v-for="(item, index) in data.couponMenuList[data.menuType].list" :key="index" class="card-coupon-box">
          <coupon-card :item="item" :coupon="item.name ? item : item.coupon" :type="data.couponMenuList[data.menuType].type" @getCouponCodeSuc="getCouponCodeSuc"></coupon-card>
        </div>
      </template>
      <template v-else-if="data.couponMenuList[data.menuType].isLoaded">
        <div class="no-order rowCC wh100">
          <img src="~img/no_coupon.png" />
        </div>
      </template>
    </div>

    <coupon-code-dialog ref="couponCodeDialogRef" @getCouponCodeSuc="getCouponCodeSuc"></coupon-code-dialog>
  </div>
</template>

<style lang="less" scoped>
  .coupon-box {
    width: var(--minWidth);
    min-height: calc(100vh - 155px - 170px);
    padding-bottom: 48px;
    margin: 0 auto;
    box-sizing: border-box;
    .coupon-header-box {
      margin-top: 30px;
      font-size: 18px;
      .redeem-coupon {
        font-size: 14px;
        font-weight: 500;
        padding: 5px 10px;
        color: #fff;
        background: var(--btnBgColor);
        border-radius: 15px;
        cursor: pointer;
      }
      .menu-box {
        border-bottom: 1px solid rgba(34, 34, 34, 0.1);
      }
      .menu-box2 {
        font-size: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(34, 34, 34, 0.1);
        .menu-item {
          cursor: pointer;
          &:hover {
            color: var(--Zcolor);
          }
          &.active {
            color: var(--Zcolor);
          }
        }
      }
    }
    .card-box {
      min-height: 300px;
      flex-wrap: wrap;
      gap: 20px;
      margin-top: 16px;
      .card-coupon-box {
        width: calc(33.33% - 20px);
        min-width: 360px;
      }
    }
  }
  .menu-box {
    .menu-item {
      position: relative;
      margin-right: 50px;
      cursor: pointer;
      &::after {
        visibility: hidden;
        content: '';
        margin-top: 16px;
        display: block;
        border-bottom: 2px solid var(--btnBgColor);
      }
      &:hover {
        color: var(--btnBgColor);
        &::after {
          visibility: inherit;
        }
      }
      &.active {
        color: var(--btnBgColor);
        &::after {
          visibility: inherit;
        }
      }
    }
  }
  .no-order {
    min-height: 400px;
  }
</style>
